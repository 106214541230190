<template>
    <div class="main">
        <div>
            <Panel module="Movimentação" title="Substituir Patrimônio" :showFilter="false" :showSearch="false"
                :showButtons="false">
                <div slot="content-main">
                    <br />
                    <div class="main">
                        <ReplacementPatrimony />
                    </div>
                </div>
            </Panel>
        </div>
    </div>
</template>
<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import ReplacementPatrimony from '../../../components/modules/stock/moviment/ReplacementPatrimony.vue'

import { mapMutations } from "vuex";

export default {
    name: "ReplacementPatrimonyView",
    components: {
        Panel, ReplacementPatrimony
    },

    mounted() {
        this.removeLoading(["panel", "btnPdvSearch"]);
    },
    methods: {
        ...mapMutations("generic", ["removeLoading", "btnPdvSearch"]),
    },
};
</script>

<style scoped>
.main {
    margin-top: 25px;
}
</style>